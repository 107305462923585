jQuery("document").ready(function () {
  cityPicker();
  readTerms();
});

function replaceQueryParam(param, newval, search) {
  var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
  var query = search.replace(regex, "$1").replace(/&$/, "");

  return (
    (query.length > 2 ? query + "&" : "?") +
    (newval ? param + "=" + newval : "")
  );
}

function cityPicker() {
  var picker = jQuery("#cityPicker");
  picker.on("change", function (e) {
    var selection = e.target.value;
    if (selection == "other") {
      toggleOtherCityField(true);
    } else {
      toggleOtherCityField(false);
    }
  });
}

function toggleOtherCityField(open) {
  var warning = jQuery("#otherWarning");
  var input = jQuery(".input--City");
  if (open) {
    warning.slideDown();
    input.slideDown();
  } else {
    warning.slideUp();
    jQuery("#City").val("");
    input.slideUp();
  }
}

function readTerms() {
  var termsContainer = jQuery(".terms-text__container");

  if (termsContainer) {
    termsContainer.scroll(function () {
      if (
        jQuery(this).scrollTop() + jQuery(this).innerHeight() + 200 >=
        jQuery(this)[0].scrollHeight
      ) {
        jQuery("#IAgree").prop("disabled", false);

        jQuery(this).unbind("scroll");
      }
    });
  }
}
