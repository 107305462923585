$nav = jQuery("#mainNav");

jQuery(document).ready(function () {
  //Menu Toggle

  $toggle = jQuery("#menuToggle");
  $menu = jQuery("#mainNav");
  $toggle.click(function () {
    $menu.toggleClass("is_open");
    $toggle.toggleClass("is_open");
    jQuery(this)
      .parent()
      .children("svg")
      .toggleClass("fa-bars")
      .toggleClass("fa-times");
  });
});
