jQuery("document").ready(function () {
  jQuery("#adjustAutoDonate").click(function () {
    jQuery("form#autoDonate").toggleClass("hidden");
    jQuery(".autoDonate__enrolled").toggleClass("hidden");
  });

  jQuery("form#autoDonate input").on('change keyup',function (e) {
    calculateCCAndTotal();
  });
});

function calculateCCAndTotal() {
  var ccFeePercent = 0.05;

  var donation = jQuery("#autoDonate input[name='donationAmt']:checked").val();
  var opCost = jQuery("#autoDonate input[name='opcostAmt']:checked").val();


  donationAmt = (donation === "other" ? parseFloat(jQuery("#autoDonate #otherAmt").val()) || 0  : parseFloat(donation) || 0)

  opCostAmt = (opCost === "other" ? parseFloat(jQuery("#autoDonate #otheropcostAmt").val()) || 0  : parseFloat(opCost) || 0)



  var ccFeeInput = jQuery("#autoDonate input[name='ccCharge']");
  var totalInput = jQuery("#autoDonate .autoDonate__total-amount");

  if (donationAmt > 0 || opCostAmt > 0) {

    var ccFee = (donationAmt + opCostAmt) * ccFeePercent;
    console.log(ccFee)
    var total = donationAmt + opCostAmt + ccFee;
    console.log(total)

    ccFeeInput.val(ccFee.toFixed(2));
    totalInput.html("$" + total.toFixed(2));
  } else {
    totalInput.html("$--");
    ccFeeInput.val(null);
  }
}
