console.log("Welcome To DreamClub United");
jQuery(document).ready(function () {

  jQuery("[data-money-picker] .money-input-wrapper").click(function (e) {
    e.stopPropagation();
    var inputWrapper = jQuery(e.target).parents('.money-input-wrapper')
    var parentForm = inputWrapper.parents('.money-input')
    var otherInput = parentForm.find("[data-other-input]")

    if (inputWrapper.attr('data-value') === "other") {
      otherInput.focus();
    } else {
      otherInput.val(null);
    }
  });


  document.addEventListener('scroll', calculateScrollPercentage);
  calculateScrollPercentage();
});


function calculateScrollPercentage() {
  const scrollPercentage = window.scrollY / (document.body.scrollHeight - window.innerHeight);
  document.documentElement.style.setProperty('--scroll-percentage', scrollPercentage.toFixed(4));
  document.documentElement.style.setProperty('--scroll-height', document.body.scrollHeight + "px");
}