console.log("Donate page");
var stripe = Stripe(window.stripe_key);
var createCheckoutSession = function (round, donationAmt, opcostAmt) {
  return fetch("/wp-json/dcu/v1/checkout/one-time", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-WP-Nonce": ajax_nonce.dcu_api_nonce,
    },
    body: JSON.stringify({
      roundID: round,
      donationAmt: donationAmt,
      opcostAmt: opcostAmt,
      cancelURL: window.location.href,
      _wpnonce: ajax_nonce.dcu_api_nonce,
    }),
  }).then(function (result) {
    return result.json();
  });
};

jQuery("document").ready(function () {  

  jQuery("form#donate input").change(function (e) {
    calculateOneTimeCCAndTotal();
  });
  
  jQuery("form#donate").submit(function (e) {
    var parentForm = jQuery(this);
    var buttonClicked = jQuery(this).children(".submit").children("#submit");

    console.log(buttonClicked);
    buttonClicked
      .addClass("loading")
      .attr("disabled", true)
      .html('<i class="fas fa-spinner fa-spin"></i>');
    e.preventDefault();
    var donation = jQuery("#donate input[name='donationAmt']:checked").val();
  var opCost = jQuery("#donate input[name='opcostAmt']:checked").val();

    var donationAmt = (donation === "other" ? parseFloat(jQuery("#donate #otherAmt").val()) || 0  : parseFloat(donation) || 0)

  var opCostAmt = (opCost === "other" ? parseFloat(jQuery("#donate #otheropcostAmt").val()) || 0  : parseFloat(opCost) || 0)
    var round = jQuery("#roundID").val();

    createCheckoutSession(round, donationAmt || 0, opCostAmt || 0).then(function (data) {
      // Call Stripe.js method to redirect to the new Checkout page
      stripe.redirectToCheckout({
        sessionId: data,
      });
    });
  });
});



function calculateOneTimeCCAndTotal() {
  var ccFeePercent = 0.05;

  var donation = jQuery("#donate input[name='donationAmt']:checked").val();
  var opCost = jQuery("#donate input[name='opcostAmt']:checked").val();


  var donationAmt = (donation === "other" ? parseFloat(jQuery("#donate #otherAmt").val()) || 0  : parseFloat(donation) || 0)

  var opCostAmt = (opCost === "other" ? parseFloat(jQuery("#donate #otheropcostAmt").val()) || 0  : parseFloat(opCost) || 0)

  
  var ccFeeInput = jQuery("#donate input[name='ccCharge']");
  var totalInput = jQuery("#donate .autoDonate__total-amount");

  if (donationAmt > 0 || opCostAmt > 0) {

    var ccFee = (donationAmt + opCostAmt) * ccFeePercent;
    console.log(ccFee)
    var total = donationAmt + opCostAmt + ccFee;
    console.log(total)

    ccFeeInput.val(ccFee.toFixed(2));
    totalInput.html("$" + total.toFixed(2));
  } else {
    totalInput.html("$--");
    ccFeeInput.val(null);
  }
}