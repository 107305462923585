var stripe = Stripe(window.stripe_key);
var createNewCheckoutSession = function (priceId, opCostAmt) {
  return fetch("/wp-json/dcu/v1/checkout/new", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-WP-Nonce": ajax_nonce.dcu_api_nonce,
    },
    body: JSON.stringify({
      priceId: priceId,
      opCostAmt: opCostAmt,
      _wpnonce: ajax_nonce.dcu_api_nonce,
    }),
  }).then(function (result) {
    return result.json();
  });
};

jQuery("document").ready(function () {
  jQuery("#nextStep").click(function () {
    e.preventDefault();
    jQuery(this).closest("form").addClass("flipped");
  });
  jQuery("#otherAmt").click(function () {
    // jQuery(".op-cost__radio:checked").attr("checked", false);
    jQuery("#choice-other").attr("checked", true);
  });

  jQuery("form#subscribe.tier_selection").change(function () {
    console.log("Form Change");
    var opCostAmt = 0;
    if (jQuery("#choice-other").is(":checked")) {
      jQuery("#otherAmt").attr("required", true);
      opCostAmt = jQuery("#otherAmt").val();
    } else {      
      jQuery("#otherAmt").attr("required", false);

      opCostAmt = jQuery(".op-cost__radio:checked").val();
    }

    console.log(jQuery(this).closest("form"));
    jQuery(this).closest("form").data("op-cost", opCostAmt);
  });

  jQuery("form#subscribe.tier_selection").submit(function (e) {
    var parentForm = jQuery(this);
    console.log(parentForm.data("priceId"));
    e.preventDefault();
    var PriceId = parentForm.data("priceId");
    var OpCostAmt = parseFloat(parentForm.data("opCost"));
    createNewCheckoutSession(PriceId, OpCostAmt || 0).then(function (data) {
      // Call Stripe.js method to redirect to the new Checkout page
      stripe
        .redirectToCheckout({
          sessionId: data,
        })
        .then(handleResult);
    });
  });

  ///////////////
  // Add Card //
  /////////////

  var createNewAddCardSession = function () {
    return fetch("/wp-json/dcu/v1/checkout/addCard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-WP-Nonce": ajax_nonce.dcu_api_nonce,
      },
      body: JSON.stringify({
        _wpnonce: ajax_nonce.dcu_api_nonce,
      }),
    }).then(function (result) {
      return result.json();
    });
  };

  jQuery("form#account__addCard").submit(function (e) {
    e.preventDefault();
    var parentForm = jQuery(this);
    var button = parentForm.find('#AddCard');
    button.attr('disabled', true);
    button.html('<i class="fa fa-spinner fa-spin"></i> Loading...');
    createNewAddCardSession().then(function (data) {
      // Call Stripe.js method to redirect to the new Checkout page
      stripe
        .redirectToCheckout({
          sessionId: data,
        })
        .then(handleResult);
    });
  });
});
